.block_container
{
    text-align:center;
}
.block_container .block {
    display:inline;
    text-align:center;
}

.hidden{
    visibility: hidden;
}
