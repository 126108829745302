body{
    background-color: beige;
    background-position: center;
    background-origin: content-box;
    background-repeat: no-repeat;
    background-size: cover;
    min-height:100vh;
    font-family: 'Noto Sans', sans-serif;
}
.text-center{
    color:#282c34;
    text-transform:uppercase;
    font-size: 23px;
    margin: -50px 0 80px 0;
    display: block;
    text-align: center;
}
.box{
    position:absolute;
    left:50%;
    top:50%;
    transform: translate(-50%,-50%);
    background-color:antiquewhite;
    border-radius:3px;
    padding:70px 100px;
}
.input-container{

    position:relative;
    margin-bottom:25px;
}
.input-container label{
    position:absolute;
    top:0px;
    left:0px;
    font-size:16px;
    color:#282c34;
    pointer-event:none;
    transition: all 0.5s ease-in-out;
}
.input-container input{
    border:0;
    border-bottom:1px solid #555;
    background:transparent;
    width:100%;
    padding:8px 0 5px 0;
    font-size:16px;
    color:#282c34;
}
.input-container input:focus{
    border:none;
    outline:none;
    border-bottom:1px solid #e74c3c;
}
.btn{
    color:#fff;
    background-color:#e74c3c;
    outline: none;
    border: 0;
    color:#282c34;
    padding:10px 20px;
    text-transform:uppercase;
    margin-top:50px;
    border-radius:2px;
    cursor:pointer;
    position:relative;
}
/*.btn:after{
	content:"";
	position:absolute;
	background:rgba(0,0,0,0.50);
	top:0;
	right:0;
	width:100%;
	height:100%;
}*/
.input-container input:focus ~ label,
.input-container input:valid ~ label{
    top:-12px;
    font-size:12px;

}
