ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #333;
}

li {
    float: left;
}

li a {
    display: block;
    color: white;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
}

/* Change the link color to #111 (black) on hover */
li a:hover {
    background-color: #111;
}

.dropdown {
    display: inline-block;
    position: relative;
}
.dropdown-content {
    display: none;
    position: absolute;
    width: 100%;
    overflow: auto;
    box-shadow: 0px 10px 10px 0px rgba(0,0,0,0.4);
}
.dropdown:hover .dropdown-content {
    display: block;
}
.dropdown-a {
    display: block;
    color: #000000;
    padding: 5px;
    text-decoration: none;
}
.dropdown-a:hover {
    color: #FFFFFF;
    background-color: #00A4BD;
}